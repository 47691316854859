import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import { Heading2 } from '../components/Typography/Typography'
import Inner from '../components/Inner'
import InfoBox from '../components/InfoBox/InfoBox'
import Price from '../components/Price/Price'
import {
  TRUNCATED_HERO_TYPES,
  TRUNCATED_HERO_BOX_SIDE_TYPES,
} from '../constants/truncatedHeroTypes'
import { BG_TYPES } from '../constants/bgTypes'
import { replaceAtToSpan, generateHero } from '../common/utils'
import FlexContentBlocks from '../common/queries/FlexContentBlocks'
import mapPriceToList from '../common/queries/mapPriceToList'
import HTMLBody from '../components/HTMLBody'

const RatesPage = ({ data }) => {
  const page = data.wp.page?.pricingAcf
  const seoData = data.wp.page?.seo

  const contentBlocks = page.contentBlocks.content

  const {
    slantDirection,
    bgGradient,
    hasBgGradient,
    isHeroHigh,
    heroBgColorMobile,
    heroBgColorDesktop,
    heroColorMobile,
    heroColorDesktop,
    infoBoxOpacity,
    infoBoxBg,
    heroImages,
  } = generateHero(page)

  const priceList = mapPriceToList(page.ratesList, page.ratesButtonsText)

  return (
    <Layout>
      <SEO title={seoData?.title} data={seoData} />
      <Container
        bg={[heroBgColorMobile, '', heroBgColorDesktop]}
        color={[heroColorMobile, '', heroColorDesktop]}
      >
        <TruncatedHero
          heroType={TRUNCATED_HERO_TYPES.withGradientOnMobile}
          heroBoxSideType={TRUNCATED_HERO_BOX_SIDE_TYPES.left}
          heroSlantType={slantDirection || 'right'}
          heroImages={heroImages}
          gradient={hasBgGradient ? bgGradient : null}
          positionImg="50% 10%"
          long={isHeroHigh}
        >
          <Container mt={['-8rem', '0']}>
            <InfoBox
              title={
                <Heading2
                  as="h1"
                  dangerouslySetInnerHTML={{
                    __html: replaceAtToSpan(page.heroFields.infoboxTitle),
                  }}
                />
              }
              content={
                <HTMLBody opacity="1" html={page.heroFields.infoboxContent} />
              }
              bgOpacity={infoBoxOpacity}
              bg={infoBoxBg}
            />
          </Container>
        </TruncatedHero>
      </Container>

      <FlexContentBlocks contentBlocks={contentBlocks} pageData={data} />

      <Container
        bg={BG_TYPES.black}
        pt={['3.125rem', '', '6.25rem']}
        pb={['2.25rem', '', '4rem']}
      >
        <Inner>
          <Price
            priceHead={page.ratesListTitle}
            priceInfo={page.underTableText}
            {...{ priceList }}
          />
        </Inner>
      </Container>
    </Layout>
  )
}

export default RatesPage

export const query = graphql`
  query {
    wp {
      page(id: "rates", idType: URI) {
        id
        title
        pricingAcf {
          ratesButtonsText
          ratesListTitle
          underTableText
          ...ratesFragment
          heroFields {
            slantDirection
            infoboxTitle
            infoboxContent
            heroHeight
            boxBackgroundOpacity
            boxBackgroundColor
            bgMobile
            bgGradient
            bgDesktop
            backgroundMobile {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 375) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundDesktop {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            action {
              showAction
              action {
                internalOrExternal
                external {
                  target
                  title
                  url
                }
                internal {
                  title
                  target {
                    ... on wp_Post {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Page {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                    ... on wp_Model {
                      contentType {
                        node {
                          name
                        }
                      }
                      slug
                    }
                  }
                }
              }
            }
          }
          contentBlocks {
            content {
              ... on wp_Page_Pricingacf_ContentBlocks_Content_PictureAndContentBox {
                fieldGroupName
                contentColTheme
                imageOrVideo
                cincopaVideoId
                image {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                negativeMargin
                pictureBoxType
                withInner
                title
                addSpacings
                content
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Pricingacf_ContentBlocks_Content_Callout {
                fieldGroupName
                bg
                pbPt
                title
                content
                blockStyle
                hasAdditionalSpacingOnBottom
                image {
                  mediaItemUrl
                }
                actions {
                  showActions
                  actions {
                    internalOrExternal
                    external {
                      target
                      title
                      url
                    }
                    internal {
                      title
                      target {
                        ... on wp_Post {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Page {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                        ... on wp_Model {
                          contentType {
                            node {
                              name
                            }
                          }
                          slug
                        }
                      }
                    }
                  }
                }
              }
              ... on wp_Page_Pricingacf_ContentBlocks_Content_ModelsSlider {
                fieldGroupName
                bg
                isAlt
              }
            }
          }
        }
        seo {
          ...seoFragment
        }
      }
    }
  }
`
