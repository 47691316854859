import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledPrice,
  StyledPriceHead,
  StyledPriceList,
  StyledPriceListItem,
  StyledPriceFooter,
} from './styles'
import PriceCard from '../PriceCard/PriceCard'

const Price = ({ priceHead, priceInfo, priceList }) => (
  <StyledPrice>
    <StyledPriceHead className="uiAnimBottom" as="h3" color="yellow">
      {priceHead}
    </StyledPriceHead>
    <StyledPriceList>
      {priceList.map(({ title, items, link }, index) => (
        <StyledPriceListItem
          isFeatured={index % 2 === 0 ? false : true}
          key={title}
        >
          <PriceCard
            title={title}
            items={items}
            link={link}
            isFeatured={index % 2 === 0 ? false : true}
          />
        </StyledPriceListItem>
      ))}
    </StyledPriceList>
    <StyledPriceFooter
      as="div"
      className="uiAnimBottom"
      opacity="1"
      color="white"
      dangerouslySetInnerHTML={{
        __html: priceInfo,
      }}
    />
  </StyledPrice>
)

export default Price

Price.propTypes = {
  priceHead: PropTypes.string.isRequired,
  priceInfo: PropTypes.string.isRequired,
  priceList: PropTypes.array.isRequired,
}
