import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import media from '../../common/MediaQueries'

const StyledPriceCard = styled.div`
  display: flex;
  padding: 1.875rem 0 2.5rem;
  width: 100%;
  background-color: ${({ theme, isFeatured }) =>
    isFeatured ? theme.colors.orange : theme.colors.white};

  ${({ isFeatured }) =>
    isFeatured &&
    css`
      ${StyledPriceCardHead} {
        color: ${({ theme }) => theme.colors.white};
      }
      ${StyledPriceCardListItem} {
        &:not(:last-child) {
          border-bottom-color: ${({ theme }) =>
            rgba(theme.colors.lightGrey3, 0.25)};
        }
      }
      ${StyledPriceCardListItemTypo} {
        color: ${({ theme }) => theme.colors.white};
      }
      ${StyledPriceCardListItemPrice} {
        color: ${({ theme }) => theme.colors.white};
      }
      @media ${media.tablet} {
        padding: 3.125rem 0 2.5rem;
      }
    `}

  a {
    width: 100%;
  }

  @media ${media.tablet} {
    ${({ isFeatured }) =>
      isFeatured &&
      css`
        z-index: 2;
        box-shadow: 0 0.125rem 3.125rem 0
            ${({ theme }) => rgba(theme.colors.orange, 0.2)},
          0 0.125rem 3.125rem 0 ${({ theme }) => rgba(theme.colors.black, 0.3)};
      `}
  }
`

const StyledPriceCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 0.5rem;
  width: 100%;
  max-width: 15.875rem;
  margin: 0 auto;
`

const StyledPriceCardHead = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-bottom: 1.125rem;
  margin-bottom: 1.875rem;
  color: ${({ theme }) => theme.colors.yellow};

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 2rem;
    height: 0.1875rem;
    transform: translateX(-50%);
    background-color: currentColor;
  }
`

const StyledPriceCardHeadTypo = styled.h3`
  font-family: ${({ theme }) => theme.fontFamilies.oswald};
  font-size: 1.5rem;
  line-height: 2;
  letter-spacing: 1.26px;
  text-align: center;
`

const StyledPriceCardList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 0.625rem;
  margin-bottom: 2.25rem;
`

const StyledPriceCardListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 0.3125rem 0.75rem 0.3125rem;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey2};
  }

  @media ${media.phone} {
    padding: 0.6875rem 0.625rem;
  }
`

const StyledPriceCardListItemTypo = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.oswald};
  color: ${({ theme }) => theme.colors.tundora};
  letter-spacing: 0.4;
  line-height: 1.3;
  word-break: break-word;
`

const StyledPriceCardListItemLabel = styled.div`
  display: inline-flex;
  flex-wrap: wrap;

  ${StyledPriceCardListItemTypo} {
    padding-right: 1rem;
  }
`

const StyledPriceCardListItemPrice = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  flex-shrink: 0;
  min-width: 3.75rem;
  color: ${({ theme }) => theme.colors.tundora};
  padding-left: 0.625rem;

  &::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -0.5rem;
    content: ':';
    line-height: 0.9;
    color: currentColor;
  }

  ${StyledPriceCardListItemTypo} {
    text-align: right;
    display: flex;
    flex-shrink: 0;
  }

  @media ${media.phone} {
    min-width: 4.5rem;
  }
`

const StyledPriceCardAction = styled.div`
  display: flex;
  justify-content: center;
`

export {
  StyledPriceCard,
  StyledPriceCardContainer,
  StyledPriceCardHead,
  StyledPriceCardHeadTypo,
  StyledPriceCardList,
  StyledPriceCardListItem,
  StyledPriceCardListItemLabel,
  StyledPriceCardListItemPrice,
  StyledPriceCardListItemTypo,
  StyledPriceCardAction,
}
