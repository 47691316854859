import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import media, { customMedia } from '../../common/MediaQueries'
import Container from '../Container'
import { decorLink, decorHoverLink } from '../../common/mixins'

const StyledInfoBoxAction = styled.div`
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.white};
  }

  @media ${media.tabletSmall} {
    text-align: left;
  }
`

const StyledInfoBoxContent = styled.div`
  p {
    line-height: 1.6875;

    & + p {
      margin-top: 1.25rem;
    }
  }

  & + ${StyledInfoBoxAction} {
    margin-top: 1.875rem;
  }

  @media ${media.tabletSmall} {
    p + p {
      margin-top: 1.75rem;
    }

    & + ${StyledInfoBoxAction} {
      margin-top: 2.25rem;
    }
  }
`

const StyledInfoBoxTitle = styled.div`
  margin-bottom: 1.25rem;

  @media ${customMedia.maxPhone} {
    br {
      display: none;
    }
  }

  @media ${media.tabletSmall} {
    margin-bottom: 2.25rem;

    span {
      display: block;
    }

    span + strong {
      margin-left: 4rem;
    }
  }

  @media ${media.tablet} {
    span + strong {
      margin-left: 5rem;
    }
  }
`

const StyledInfoBoxTag = styled.span`
  position: relative;
  display: block;
  font-size: 0.75rem;
  letter-spacing: 7.5px;
  line-height: 4.16;
  text-transform: uppercase;
  padding-left: 2.6875rem;
  margin-top: 1rem;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -0.125rem;
    left: 0;
    width: 3.125rem;
    height: 0.0625rem;
    background: ${({ theme }) => rgba(theme.colors.white, 0.5)};
  }
`

const StyledInfoBox = styled(Container)`
  padding: 1.875rem 1.875rem 3.125rem 1.875rem;
  z-index: 1;
    
  ${StyledInfoBoxTitle},
  ${StyledInfoBoxContent} {
    a {
      color: ${({ theme }) => theme.colors.yellow};
      ${decorLink};

      &:hover {
        ${decorHoverLink};
      }
    }
  }

  @media ${customMedia.maxTabletSmall} {
    ${({ alt }) =>
      !alt &&
      css`
        background: transparent;
      `}


    ${({ full }) =>
      full &&
      css`
        margin-left: 1.875rem;
        margin-right: 1.875rem;
        padding: 1.25rem 0 3.125rem 0;
      `}
  }

  @media ${media.tabletSmall} {
    z-index: 1;
    max-width: 28.5rem;
    width: 100%;
    padding: 3rem 2.75rem;
    color: ${({ theme }) => theme.colors.white};

    ${({ bg }) =>
      bg === 'black' &&
      css`
        background: ${({ theme, bgOpacity }) =>
          rgba(theme.colors.black, bgOpacity)};
      `}

    ${({ bg }) =>
      bg === 'lightBlack' &&
      css`
        background: ${({ theme, bgOpacity }) =>
          rgba(theme.colors.darkGrey, bgOpacity)};
      `}

    ${({ bg }) =>
      bg === 'white' &&
      css`
        background: ${({ theme, bgOpacity }) =>
          rgba(theme.colors.white, bgOpacity)};
        color: ${({ theme }) => theme.colors.black};
      `}

    ${({ bg }) =>
      bg === 'lightGrey' &&
      css`
        background: ${({ theme, bgOpacity }) =>
          rgba(theme.colors.lightGrey4, bgOpacity)};
        color: ${({ theme }) => theme.colors.black};
      `}

    ${({ wide }) =>
      wide &&
      css`
        max-width: 35.5rem;
        ${StyledInfoBoxContent} {
          max-width: 90%;
        }
      `}   

    ${({ full }) =>
      full &&
      css`
        max-width: 35.5rem;
        ${StyledInfoBoxContent} {
          max-width: 100%;
        }
      `}
  }
`

export {
  StyledInfoBox,
  StyledInfoBoxTag,
  StyledInfoBoxTitle,
  StyledInfoBoxContent,
  StyledInfoBoxAction,
}
