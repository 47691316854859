import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledInfoBox,
  StyledInfoBoxTag,
  StyledInfoBoxTitle,
  StyledInfoBoxContent,
  StyledInfoBoxAction,
} from './styles'
import { BG_TYPES } from '../../constants/bgTypes'

const InfoBox = ({
  bg,
  bgOpacity,
  wide,
  full,
  alt,
  tag,
  title,
  content,
  action,
}) => (
  <StyledInfoBox
    wide={wide}
    full={full}
    alt={alt}
    bg={bg}
    bgOpacity={bgOpacity}
  >
    <div className="uiAnimCustomBottom">
      {tag && <StyledInfoBoxTag>{tag}</StyledInfoBoxTag>}
      <StyledInfoBoxTitle>{title}</StyledInfoBoxTitle>
      <StyledInfoBoxContent>{content}</StyledInfoBoxContent>
      {action && <StyledInfoBoxAction>{action}</StyledInfoBoxAction>}
    </div>
  </StyledInfoBox>
)

export default InfoBox

InfoBox.defaultProps = {
  bgOpacity: 1,
  bg: BG_TYPES.black,
  alt: false,
  wide: false,
  full: false,
}

InfoBox.propTypes = {
  bgOpacity: PropTypes.number,
  bg: PropTypes.string,
  wide: PropTypes.bool,
  full: PropTypes.bool,
  tag: PropTypes.node,
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  action: PropTypes.node,
  alt: PropTypes.bool,
}
