import styled, { css } from 'styled-components'

import media from '../../common/MediaQueries'
import { Heading5, Body } from '../Typography/Typography'

const StyledPrice = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 65rem;
`

const StyledPriceHead = styled(Heading5)`
  text-align: center;
  margin-bottom: 2.25rem;

  @media ${media.tablet} {
    margin-bottom: 3rem;
  }
`

const StyledPriceList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2.5rem;

  @media ${media.phone} {
    flex-direction: row;
  }
`

const StyledPriceListItem = styled.li`
  display: flex;
  flex-basis: 100%;
  max-width: 100%;

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  @media ${media.phone} {
    flex-basis: 50%;
    max-width: 50%;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  @media ${media.tablet} {
    padding: 1.125rem 0;
    flex-basis: 32.5%;
    max-width: 32.5%;
  }

  ${({ isFeatured }) =>
    isFeatured &&
    css`
      @media ${media.tablet} {
        flex-basis: 35%;
        max-width: 35%;
        padding: 0;
      }
    `};
`

const StyledPriceFooter = styled(Body)`
  text-align: center;
  width: 100%;
  margin: 0 auto;
  max-width: 42rem;

  strong {
    color: currentColor;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`

export {
  StyledPrice,
  StyledPriceHead,
  StyledPriceList,
  StyledPriceListItem,
  StyledPriceFooter,
}
