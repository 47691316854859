import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import {
  StyledPriceCard,
  StyledPriceCardContainer,
  StyledPriceCardHead,
  StyledPriceCardHeadTypo,
  StyledPriceCardList,
  StyledPriceCardListItem,
  StyledPriceCardListItemLabel,
  StyledPriceCardListItemPrice,
  StyledPriceCardListItemTypo,
  StyledPriceCardAction,
} from './styles'
import InternalLink from '../Buttons/InternalLink/InternalLink'
import { BUTTON_TYPES } from '../../constants/buttonTypes'
import { URL_PATHS } from '../../constants/urlPaths'

const PriceCard = ({ title, items, link, isFeatured }) => {
  const { title: linkTitle, target } = link

  return (
    <StyledPriceCard isFeatured={isFeatured} className="uiAnimBottom">
      <StyledPriceCardContainer>
        {title && (
          <StyledPriceCardHead>
            <StyledPriceCardHeadTypo>{title}</StyledPriceCardHeadTypo>
          </StyledPriceCardHead>
        )}
        <StyledPriceCardList>
          {items.map(({ label: itemTitle, price: itemPrice }) => (
            <StyledPriceCardListItem key={itemTitle}>
              <StyledPriceCardListItemLabel>
                <StyledPriceCardListItemTypo>
                  {itemTitle}
                </StyledPriceCardListItemTypo>
              </StyledPriceCardListItemLabel>
              <StyledPriceCardListItemPrice>
                <StyledPriceCardListItemTypo>
                  {itemPrice}
                </StyledPriceCardListItemTypo>
              </StyledPriceCardListItemPrice>
            </StyledPriceCardListItem>
          ))}
        </StyledPriceCardList>
        <StyledPriceCardAction>
          {target ? (
            <InternalLink
              as="a"
              href={URL_PATHS.BOOKINGS}
              target={target}
              rel="noopener noreferrer nofollow"
              btnType={!isFeatured ? BUTTON_TYPES.primary : BUTTON_TYPES.light}
            >
              {linkTitle}
            </InternalLink>
          ) : (
            <InternalLink
              to={URL_PATHS.BOOKINGS}
              btnType={!isFeatured ? BUTTON_TYPES.primary : BUTTON_TYPES.light}
            >
              {linkTitle}
            </InternalLink>
          )}
        </StyledPriceCardAction>
      </StyledPriceCardContainer>
    </StyledPriceCard>
  )
}

export default PriceCard

PriceCard.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      price: PropTypes.string.isRequired,
    }),
  ),
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  isFeatured: PropTypes.bool,
}

export const query = graphql`
  fragment ratesFragment on wp_Page_Pricingacf {
    ratesList {
      columnTitle
      prices {
        label
        price
      }
    }
  }
`
